import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "my-3 2xl:my-5 py-3 px-10 2xl:py-1 2xl:px-20" }
const _hoisted_2 = { class: "p-10 rounded-lg bg-opacity-90" }
const _hoisted_3 = { class: "grid grid-cols-1 lg:grid-cols-2 gap-16 xl:gap-28" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "el-icon el-icon--upload" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "el-icon el-icon--upload" }
const _hoisted_11 = { class: "" }
const _hoisted_12 = { class: "flex justify-end items-center border-t-2 2xl:space-x-5 space-x-3 2xl:py-5 py-3 mt-10" }
const _hoisted_13 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSvgIcon = _resolveComponent("BaseSvgIcon")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_Field = _resolveComponent("Field")!
  const _component_v_color_picker = _resolveComponent("v-color-picker")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "w-full 2xl:py-5 py-3 flex justify-between items-center mb-2" }, [
        _createElementVNode("h1", {
          class: "text-2xl 2xl:text-4xl font-bold text-white",
          "data-aos": "fade-right"
        }, " Settings ")
      ], -1)),
      _createVNode(_component_Form, {
        as: "el-form",
        onSubmit: _ctx.onSubmit,
        "label-position": "top"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_Field, { name: "customerLogo" }, {
                default: _withCtx(({ value, field, errorMessage }) => [
                  _createVNode(_component_el_form_item, {
                    class: "mb-0",
                    error: errorMessage,
                    label: "Upload Logo (max 200kb)"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_upload, {
                        class: "file-uploader",
                        action: "https://jsonplaceholder.typicode.com/posts/",
                        drag: "",
                        "show-file-list": false,
                        "on-success": (res, file) => _ctx.handleUploadImageSuccess(res, file, field),
                        "before-upload": _ctx.beforeUploadImage
                      }, {
                        default: _withCtx(() => [
                          value
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                src: value + '',
                                class: ""
                              }, null, 8, _hoisted_5))
                            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                _createElementVNode("i", _hoisted_7, [
                                  _createVNode(_component_BaseSvgIcon, {
                                    class: "inline-block text-white group-hover:text-indino-primary w-6 h-6",
                                    name: "picture"
                                  })
                                ]),
                                _cache[0] || (_cache[0] = _createElementVNode("div", { class: "el-upload__text" }, [
                                  _createTextVNode(" Drop file here or "),
                                  _createElementVNode("em", null, "click to upload")
                                ], -1))
                              ]))
                        ]),
                        _: 2
                      }, 1032, ["on-success", "before-upload"]),
                      _createVNode(_component_el_input, _mergeProps({
                        class: "hidden",
                        hidden: ""
                      }, field, { "model-value": value }), null, 16, ["model-value"])
                    ]),
                    _: 2
                  }, 1032, ["error"])
                ]),
                _: 1
              }),
              _createVNode(_component_Field, { name: "customerBackgroundImage" }, {
                default: _withCtx(({ value, field, errorMessage }) => [
                  _createVNode(_component_el_form_item, {
                    class: "mb-0",
                    error: errorMessage,
                    label: "Upload Background (max 800kb)"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_upload, {
                        class: "file-uploader background-image",
                        action: "https://jsonplaceholder.typicode.com/posts/",
                        drag: "",
                        "show-file-list": false,
                        "on-success": (res, file) => _ctx.handleUploadImageSuccess(res, file, field),
                        "before-upload": _ctx.beforeUploadImage
                      }, {
                        default: _withCtx(() => [
                          value
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                src: value + '',
                                class: ""
                              }, null, 8, _hoisted_8))
                            : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                _createElementVNode("i", _hoisted_10, [
                                  _createVNode(_component_BaseSvgIcon, {
                                    class: "inline-block group-hover:text-indino-primary text-white w-6 h-6",
                                    name: "picture"
                                  })
                                ]),
                                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "el-upload__text" }, [
                                  _createTextVNode(" Drop file here or "),
                                  _createElementVNode("em", null, "click to upload")
                                ], -1))
                              ]))
                        ]),
                        _: 2
                      }, 1032, ["on-success", "before-upload"]),
                      _createVNode(_component_el_input, _mergeProps({
                        class: "hidden",
                        hidden: ""
                      }, field, { "model-value": value }), null, 16, ["model-value"])
                    ]),
                    _: 2
                  }, 1032, ["error"])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_v_color_picker, {
                "dot-size": "25",
                "swatches-max-height": "200"
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _cache[2] || (_cache[2] = _createElementVNode("button", {
              type: "reset",
              class: "btn btn--gray"
            }, "Cancel", -1)),
            _createElementVNode("button", {
              type: "submit",
              class: "btn btn--red-primary",
              disabled: _ctx.isSubmitting
            }, " Create ", 8, _hoisted_13)
          ])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ])
  ]))
}